import {pageReady} from "../modules/utils";
import "swiper/dist/css/swiper.min.css";
import Swiper from "swiper/dist/js/swiper";
import "latte-carousel/dist/latte-carousel.min.css";
import { Carousel } from "latte-carousel";

pageReady(() => {
  new Swiper ('#mypet-main-page__discounts-swiper', {
    direction: 'horizontal',
    loop: true,
    speed: 1100,
    preloadImages: false,
    autoplay: {
      delay: 5000,
    },
    lazy: true,
    pagination: {
      el: '#mypet-main-page__discounts-swiper-pagination',
      clickable: true,
    },
  });

  const options = {
    count: 3,
    move: 1,
    touch: true,
    mode: "align",
    buttons: false,
    dots: true,
    rewind: true,
    autoplay: 0,
    animation: 500,
    responsive: {
      "0": { count: 1 },
      "480": { count: 2 },
      "800": { count: 3 },
      "950": { count: 4 },
      "1120": { count: 1 },
    },
  };

  const carousel = new Carousel("#mypet-main-page__reviews-swiper", options);

  document.querySelector("#mypet-main-page__reviews-swiper").querySelectorAll(".latte-item").forEach((element) => {
    element.classList.remove("latte-carousel__item-init");
  })

  /*new Swiper ('#mypet-main-page__reviews-swiper', {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 0,
    freeMode: true,
    pagination: {
      el: '#mypet-main-page__reviews-swiper-pagination',
      clickable: true,
    },
  })*/
});
